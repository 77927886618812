import * as API from '@/api/index'

export default {
    nursePlanMode: params => {
        return API.POST('api/nursePlanMode/all', params)
    },
    create: params => {
        return API.POST('api/nursePlanMode/create', params)
    },
    update: params => {
        return API.POST('api/nursePlanMode/update', params)
    },
    skinInstrument: params => {
        return API.POST("api/skinInstrument/list", params)
    },
    skinCategory: params => {
        return API.POST("api/skinCategory/all", params)
    },
    Unitlist:params=>{
        return API.POST("api/skinUsePeriodUnit/list",params)
    },
    goodList:params=>{
        return API.POST("api/nursePlanMode/good",params)
    },
    // 获取产品
    savingCardProduct: params => {
        return API.POST('api/savingCard/product', params)
    },
    // 获取项目
    savingCardProject: params => {
        return API.POST('api/savingCard/project', params)
    },
}