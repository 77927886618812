<template>
  <div class="BodyParts content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="名称">
              <el-input
                clearable
                placeholder="输入名称搜索"
                v-model="searchName"
              ></el-input>
            </el-form-item>
            <el-form-item label="仪器编号">
              <el-input
                clearable
                placeholder="输入仪器编号"
                v-model="InstrumentID"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型编号">
              <el-input
                clearable
                placeholder="输入类型编号"
                v-model="CategoryID"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select
                v-model="Active"
                placeholder="请选择"
                @change="handleSearch"
              >
                <el-option
                  v-for="item in ActiveList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="addParts"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
        style="width: 100%"
      >
        <el-table-column
          label="仪器名称"
          prop="InstrumentName"
        ></el-table-column>
        <el-table-column
          label="仪器类型名称"
          prop="CategoryName"
        ></el-table-column>
        <el-table-column label="模板名称" prop="Name"></el-table-column>
        <el-table-column label="周期" prop="UsePeriod"></el-table-column>
        <el-table-column label="周期单位名称" prop="Unit"></el-table-column>
        <el-table-column label="操作人姓名" prop="EmpName"></el-table-column>
        <el-table-column label="有效性" prop="Active">
          <template slot-scope="scope">{{
            scope.row.Active ? "有效" : "无效"
          }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="editor(scope.row)" size="small"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="deleter(scope.row)"
              v-if="isDelete"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 添加编辑弹窗 -->
    <el-dialog
      :title="dialogtype == 1 ? '新增护理计划模板' : '编辑护理计划模板'"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="closeDialog"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="1">
          <div class="pad_15">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="120px"
              size="small"
            >
              <el-row>
                <el-col :span="12">
                  <el-form-item label="仪器编号" prop="InstrumentID">
                    <el-select
                      v-model="ruleForm.InstrumentID"
                      placeholder="请选择"
                      class="width_220"
                    >
                      <el-option
                        v-for="item in Instrumentlist"
                        :key="item.InstrumentID"
                        :label="item.Name"
                        :value="item.InstrumentID"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="类型编号" prop="CategoryID">
                    <el-select
                      v-model="ruleForm.CategoryID"
                      placeholder="请选择"
                      class="width_220"
                    >
                      <el-option
                        v-for="item in CategoryIDList"
                        :key="item.CategoryID"
                        :label="item.Name"
                        :value="item.CategoryID"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="名称" prop="Name">
                    <el-input
                      clearable
                      v-model="ruleForm.Name"
                      placeholder="请输入名称"
                      class="width_220"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="周期" prop="UsePeriod">
                    <el-input
                      type="number"
                      clearable
                      v-model="ruleForm.UsePeriod"
                      placeholder="请输入周期"
                      class="width_220"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="周期单位" prop="Unit">
                    <el-select
                      v-model="ruleForm.Unit"
                      placeholder="请选择"
                      class="width_220"
                    >
                      <el-option
                        v-for="item in Unitlist"
                        :key="item.Unit"
                        :label="item.Name"
                        :value="item.Unit"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="有效性" prop="Active">
                    <el-radio v-model="ruleForm.Active" :label="true"
                      >有效</el-radio
                    >
                    <el-radio v-model="ruleForm.Active" :label="false"
                      >无效</el-radio
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="适用商品" name="2">
          <el-row class="marbm_10">
            <el-col :span="6">
              <el-input
                placeholder="输入关键字查询"
                clearable
                v-model="filterProjectName"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-button type="primary" size="small" @click="addSetDetails"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <el-table
            size="small"
            :data="
              goodsRangeList.filter(
                (data) =>
                  !filterProjectName ||
                  data.Name.toLowerCase().includes(
                    filterProjectName.toLowerCase()
                  )
              )
            "
            max-height="400"
          >
            <el-table-column prop="Name" label="商品名称"></el-table-column>
            <el-table-column prop="TypeName" label="商品类型"></el-table-column>
            <el-table-column prop="Amount" label="数量">
              <template slot-scope="scope">
                <el-input
                  class="width_120"
                  size="small"
                  type="number"
                  min="1"
                  v-model.number="scope.row.Amount"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  @click="remove(1, scope.row, scope.$index)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加商品弹窗 -->
    <el-dialog title="添加适用商品" :visible.sync="dialogScope" width="900px">
      <div style="height: 60vh">
        <el-scrollbar class="el-scrollbar_height">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-collapse v-model="collapseIndex" accordion>
                <el-collapse-item title="产品" name="1">
                  <el-input
                    v-model="productName"
                    placeholder="请输入关键词进行搜索"
                    clearable
                    size="small"
                  ></el-input>
                  <el-tree
                    ref="treeProduct"
                    :data="ProductList"
                    node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }"
                    :filter-node-method="filterNode"
                    :default-checked-keys="productDefaultCheckedKeys"
                    :default-expanded-keys="productDefaultExpandedKeys"
                  >
                    <div slot-scope="{ data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag
                            class="marlt_5"
                            type="info"
                            size="mini"
                            v-if="data.IsGoods == 0"
                          >
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button
                            type="text"
                            @click="changeAll(1, data)"
                            size="mini"
                            v-if="data.IsGoods == 1"
                            >添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-collapse-item>
                <el-collapse-item title="项目" name="2">
                  <el-input
                    v-model="projectName"
                    placeholder="请输入关键词进行搜索"
                    clearable
                    size="small"
                  ></el-input>
                  <el-tree
                    ref="treeProject"
                    :data="ProjectList"
                    node-key="PID"
                    :props="{ children: 'Child', label: 'Name' }"
                    :filter-node-method="filterNode"
                    :default-checked-keys="projectDefaultCheckedKeys"
                    :default-expanded-keys="projectDefaultExpandedKeys"
                  >
                    <div slot-scope="{ data }" style="width: 100%">
                      <el-row type="flex" justify="space-between">
                        <el-col :span="18">
                          <span>{{ data.Name }}</span>
                          <el-tag
                            class="marlt_5"
                            type="info"
                            size="mini"
                            v-if="data.IsGoods == 0"
                          >
                            分类
                          </el-tag>
                        </el-col>
                        <el-col :span="6" style="text-align: right">
                          <el-button
                            type="text"
                            @click="changeAll(2, data)"
                            size="mini"
                            v-if="data.IsGoods == 1"
                            >添加
                          </el-button>
                        </el-col>
                      </el-row>
                    </div>
                  </el-tree>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col :span="16">
              <!--展示全部-->
              <el-table
                size="small"
                max-height="500px"
                :data="packageCardSelectAll"
              >
                <el-table-column prop="Name" label="商品名称"></el-table-column>
                <el-table-column
                  prop="TypeName"
                  label="商品类型"
                ></el-table-column>
                <!-- <el-table-column
                prop="OldPrice"
                label="销售价格"
              ></el-table-column> -->
                <el-table-column label="操作" width="80px">
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      size="small"
                      @click="remove(2, scope.row, scope.$index)"
                      >删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-scrollbar>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogScope = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addProjectSelect"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Instrument/NursePlanMode";
import permission from "@/components/js/permission.js";
export default {
  name: "nursePlanMode",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      productDefaultCheckedKeys: [], //产品回显
      productDefaultExpandedKeys: [],
      projectDefaultCheckedKeys: [], //项目回显
      projectDefaultExpandedKeys: [],
      packageCardSelectAll: [], //选择的全部商品
      goodsRangeList: [],
      collapseIndex: "",
      dialogScope: false,
      filterProjectName: "",
      projectName: "",
      productName: "",
      addScopeDialogState: false,
      Active: null,
      ActiveList: [
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
        { Name: "全部", Value: null },
      ],
      ProjectList: [],
      ProductList: [],
      activeName: "1",
      isDelete: "", //删除权限
      dialogtype: 1,
      dialogVisible: false,
      searchName: "",
      CategoryID: "",
      InstrumentID: "",
      tableData: [],
      Instrumentlist: [], //仪器编号
      CategoryIDList: [], //类型编号
      Unitlist: [], //单位列表
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      ruleForm: {
        Active: true,
      },
      rules: {
        CategoryID: [
          { required: true, message: "请输入类型编号", trigger: "blur" },
        ],
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Active: [{ required: true, message: "请选择有效性" }],
        InstrumentID: [
          { required: true, message: "请选择仪器编号", trigger: "blur" },
        ],
        Unit: [{ required: true, message: "请选择周期单位", trigger: "blur" }],
        UsePeriod: [{ required: true, message: "请填写周期", trigger: "blur" }],
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**路由守卫 */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(to.meta.Permission, "检测项目");
    });
  },
  /**  方法集合  */
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    //设置明细中选择的产品、项目...
    changeAll(index, data) {
      if (this.packageCardSelectAll.some((item) => item.ID == data.ID)) {
        this.$message.warning("该商品已添加!");
        return;
      }
      this.packageCardSelectAll.push({
        PID: data.PID,
        ID: data.ID,
        Amount: data.Price,
        OldPrice: data.Price,
        Price: data.Price,
        GoodsCategoryName: data.GoodsCategoryName,
        TotalPrice: "",
        TypeName: index === 1 ? "产品" : "项目",
        Name: data.Name,
      });
    },
    remove(type, row, indexall) {
      var that = this;
      if (type == 1) {
        that.goodsRangeList.splice(indexall, 1);
      } else {
        that.packageCardSelectAll.splice(indexall, 1);
      }
    },
    addProjectSelect() {
      var that = this;
      that.goodsRangeList = Object.assign([], that.packageCardSelectAll);

      that.dialogScope = false;
    },
    // 添加项目产品
    addSetDetails() {
      var that = this;
      that.packageCardSelectAll = Object.assign([], that.goodsRangeList);
      that.dialogScope = true;
    },
    // 模板列表
    handleSearch() {
      var that = this;
      var params = {
        Active: that.Active,
        Name: that.searchName,
        PageNum: that.paginations.page,
        InstrumentID: that.InstrumentID,
        CategoryID: that.CategoryID,
      };
      API.nursePlanMode(params).then((res) => {
        if (res.StateCode == 200) {
          that.tableData = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 新增弹窗
    addParts() {
      this.dialogtype = 1;
      this.dialogVisible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    // 删除模板
    deleter(row) {
      this.$confirm("此操作将永久删除此检测项目, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已删除",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 编辑模板
    editor(row) {
      const {
        ModelID,
        InstrumentID,
        CategoryID,
        Name,
        UsePeriod,
        Unit,
        Active,
      } = row;
      this.goodsRangeList = [];
      this.getGoods(ModelID);
      this.ruleForm = {
        ModelID,
        InstrumentID,
        CategoryID,
        Name,
        UsePeriod,
        Unit,
        Active,
      };
      this.dialogtype = 2;
      this.dialogVisible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    // 翻页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.handleSearch();
    },
    // 关闭弹窗
    closeDialog() {
      this.ruleForm = {
        Active: true,
      };
      this.goodsRangeList = [];
      this.activeName = "1";
      this.dialogVisible = false;
    },
    // 表单提交
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createParts();
          } else {
            that.updateParts();
          }
        } else {
          that.activeName = "1";
        }
      });
    },
    // 新增接口
    createParts() {
      var that = this;
      const {
        Active,
        CategoryID,
        InstrumentID,
        Name,
        Unit,
        UsePeriod,
      } = that.ruleForm;
      var Good = that.goodsRangeList.map((item) => {
        return {
          ProductID: item.ID,
          TypeName: item.TypeName,
          ProductName: item.ProductName,
          Amount: item.Amount,
        };
      });
      var params = {
        Active,
        CategoryID,
        InstrumentID,
        Name,
        Unit,
        Good,
        UsePeriod,
      };
      API.create(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已添加!");
          that.closeDialog();
          that.handleSearch();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 更新接口
    updateParts() {
      var that = this;
      const {
        Active,
        CategoryID,
        InstrumentID,
        Name,
        Unit,
        UsePeriod,
        ModelID,
      } = that.ruleForm;
      var Good = that.goodsRangeList.map((item) => {
        return {
          ProductID: item.ID,
          TypeID: item.TypeName == "产品" ? "1" : "2",
          Amount: item.Amount,
        };
      });
      var params = {
        ModelID,
        Active,
        CategoryID,
        InstrumentID,
        Name,
        Unit,
        Good,
        UsePeriod,
      };
      API.update(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已修改!");
          this.closeDialog();
          that.handleSearch();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 仪器编号
    skinInstrument() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinInstrument(params).then((res) => {
        if (res.StateCode == 200) {
          that.Instrumentlist = res.Data;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 皮肤类型
    getSkinCategory() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinCategory(params).then((res) => {
        if (res.StateCode == 200) {
          that.CategoryIDList = res.List;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 周期单位
    getUnitlist() {
      API.Unitlist().then((res) => {
        if (res.StateCode == 200) {
          this.Unitlist = res.Data;
        } else {
          this.$message(res.Message);
        }
      });
    },
    // 适用产品
    getGoods(ModeID) {
      var that = this;
      var params = {
        ModeID,
      };
      API.goodList(params).then((res) => {
        if (res.StateCode == 200) {
          res.Data.forEach((item) => {
            item.Name = item.ProductName;
            item.ID = item.ProductID;
          });
          that.goodsRangeList = Object.assign([], res.Data);
        } else {
          that.$messge.error(res.Message);
        }
      });
    },
    // 获取产品
    productData: function () {
      var that = this;
      let params = {
        productName: that.productName,
      };
      API.savingCardProduct(params).then((res) => {
        if (res.StateCode == 200) {
          that.ProductList = res.Data;
          that.setRecursion(res.Data);
        } else {
          this.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    // 获取项目
    projectData: function () {
      var that = this;
      API.savingCardProject().then((res) => {
        if (res.StateCode == 200) {
          that.ProjectList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 递归
    setRecursion(data) {
      var that = this;
      for (let i = 0; i <= data.length - 1; i++) {
        if (data[i].IsGoods == 0) {
          data[i].PID = "0" + data[i].ID;
        } else {
          data[i].PID = "1" + data[i].ID;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
  },
  /** 监听数据变化   */
  watch: {
    productName(val) {
      this.$refs.treeProduct.filter(val);
    },
    projectName(val) {
      this.$refs.treeProject.filter(val);
    },
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.isDelete = permission.permission(
      this.$route.meta.Permission,
      "检测项目"
    );
    this.projectData();
    this.productData();
    this.handleSearch();
    this.skinInstrument();
    this.getSkinCategory();
    this.getUnitlist();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.dialog_header {
  padding: 12px 0;
}
.width_120 {
  width: 120px;
}
.el-scrollbar_height {
  height: 100%;
  .header_icon {
    background-color: #f4fbff;
    border: 1px solid #4861fc;
    padding: 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .is-horizontal {
      display: none;
    }
}
</style>
